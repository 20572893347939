<template>
  <div class="widgetContainer replaceCardDetails">
    <div class="loginHeader">
      <img class="loginHeader__logo" :src="logoUrl" alt="logo">
      <div class="loginHeader__footer">
        <p class="cancel" @click="cancel">
          {{ $t('cancel') }}
        </p>
        <p class="description">
          {{ $t('card.text.replaceCard') }}
        </p>
        <p class="submit" @click="next">
          {{ $t('next') }}
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="replaceCard__details card">
        <ul>
          <li>
            <div class="icon initialsInfo">
              <span class="icon-card" />
            </div>
            <div class="details">
              <p class="value">
                {{ $t('card.text.replaceCardWarning') }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="replaceCard__reasons card">
        <p class="sub-header">
          {{ $t('card.text.reason') }}
        </p>
        <ul>
          <li>
            <div class="icon initialsInfo">
              <span class="icon-paperplane" />
            </div>
            <div class="details">
              <p class="label">
                {{ $t('card_label') }}
              </p>
              <p class="value">
                {{ label }}
              </p>
            </div>
            <div class="action" @click="labelEdit">
              <p class="select">
                {{ $t('Edit') }}
              </p>
            </div>
          </li>
          <li>
            <div class="icon initialsInfo">
              <span class="icon-paperplane" />
            </div>
            <div class="details">
              <p class="label">
                {{ $t('card_spendLimit') }}
              </p>
              <p class="value">
                {{ spendLimit }}
              </p>
            </div>
            <div class="action" @click="spendLimitEdit">
              <p class="select">
                {{ $t('Edit') }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { cardLimit } from '../enums/index';
export default {
  computed: {
    ...mapGetters('card', ['getSelectedCard', 'getReplaceCardData']),
    label() {
      if (this.getReplaceCardData && this.getReplaceCardData.label) {
        return this.getReplaceCardData.label;
      }
      return this.getSelectedCard.label;
    },
    limitAmount() {
      if (this.getReplaceCardData && this.getReplaceCardData.limitAmount) {
        return this.getReplaceCardData.limitAmount;
      }
      return this.getSelectedCard.limitAmount;
    },
    limitInterval() {
      if (this.getReplaceCardData && this.getReplaceCardData.limitInterval) {
        return this.getReplaceCardData.limitInterval;
      }
      return this.getSelectedCard.limitInterval;
    },
    spendLimit() {
      return this.limitAmount + ' ' + cardLimit[this.limitInterval];
    }
  },
  methods: {
    ...mapMutations('card', ['updateReplaceCardDate']),
    labelEdit() {
      this.$router.push('/card/labelForm');
    },
    spendLimitEdit() {
      this.$router.push('/card/spendForm');
    },
    cancel() {},
    next() {
      this.updateReplaceCardDate({
        ...this.getReplaceCardData,
        label: this.label,
        limitAmount: this.limitAmount,
        limitInterval: this.limitInterval
      });
      this.$router.push('/card/replaceCardAddress');
    }
  }
};
</script>
<style lang="scss" scoped>
.replaceCardDetails {
  .card {
    font-family: 'SF-Pro-Text';
    .sub-header {
      color: rgba(#3c3c43, 0.6);
      font-size: 13px;
      padding-bottom: 7px;
      text-transform: uppercase;
    }
    ul {
      background-color: #fff;
      border-radius: 15px;
      padding: 16px;
      margin-bottom: 30px;
      max-height: 192px;
      overflow-x: hidden;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .icon {
          background-color: var(--branding);
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          font-weight: 500;
          width: 40px;
          min-width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #fff;
          }
        }

        .details {
          margin: 0 auto 0 15px;
          padding-right: 15px;
          .label {
            font-size: 15px;
            line-height: 20px;
          }

          .value {
            color: rgba(#3c3c43, 0.6);
            font-size: 12px;
            padding-top: 2px;
          }
        }

        .action {
          cursor: pointer;

          &:hover {
            .icon-arrow-right {
              color: #000;
            }
          }

          .icon-arrow-right {
            padding: 10px;
            color: #c7c7c9;
            transition: 0.3s;
          }

          .details {
            border-radius: 20px;
            background-color: rgba(#5856d6, 0.1);
            color: var(--branding);
            font-size: 13px;
            font-weight: 700;
            padding: 7px 9px;
            text-transform: uppercase;
          }
          .select {
            border-radius: 20px;
            background-color: rgba(#5856d6, 0.1);
            color: var(--branding);
            font-size: 13px;
            font-weight: 700;
            padding: 7px 9px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
</style>
